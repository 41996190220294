/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Hero from 'components/hero';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function AddRestaurant () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.add-restaurant.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.add-restaurant.meta_desc' })} />
			</Helmet>
		<Section>
			<iframe 
				title="Demande de Menu QR Code"
				src="https://docs.google.com/forms/d/e/1FAIpQLScS76H1ED--ChuazrifRW4mG50jiH8FvRdGRXfeurnZPOMqyA/viewform?embedded=true" 
				width="100%" 
				height="3282" 
				frameborder="0" 
				marginheight="0" 
				marginwidth="0">Loading…</iframe>
		</Section>
		</React.Fragment>
	);
}